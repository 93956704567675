import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import {
  BackofficePage,
  Page,
  ToggleSidebarBtnContainer,
} from 'Components/Layout';
import { Button } from 'ui-55';

import NavigationBar from 'Components/NavigationBar';
import Sidebar from 'Components/Sidebar';

import useAuth from 'Hooks/useAuth';

import Archive from 'Pages/Archive';
import Requests from 'Pages/Requests';
import Specialists from 'Pages/Specialists';
import Candidates from 'Pages/Candidates';
import RequestDetails from 'Pages/RequestDetail';
import CreateNewRequest from 'Pages/NewRequest';
import SpecialistDetails from 'Pages/SpecialistDetail';
import NewCandidate from 'Pages/NewCandidate';
import InfoGraphs from 'Pages/InfoGraphs';

import Settings from 'Pages/Settings';
import Team from 'Pages/Team';
import Tags from 'Pages/Tag';
import MemberDetail from 'Pages/MemberDetail';
// import { ClientList } from 'features/clients/ClientList';
import { ClientDetail } from 'features/clients/ClientDetail';
import { ContentList } from 'features/content/ContentList';
import Clients from 'Pages/Clients';
import { ContentDetail } from 'features/content/ContentDetail';
import { CityList } from 'features/cities/CityList';
import { CityDetail } from 'features/cities/CityDetail';
import { CityEdit } from 'features/cities/CityEdit';


const routes = [
  //──── Requests ──────────────────────────────────────────────────────────────────────────
  {
    path: '/dashboard/archive',
    component: Archive,
    title: 'services',
    location: 'archive',
  },
  {
    path: '/dashboard/requests',
    component: Requests,
    title: 'services',
    location: 'requests',
  },
  {
    path: '/dashboard/tags',
    component: Tags,
    title: 'tags',
    location: 'tags',
  },
  {
    path: '/dashboard/request-details',
    component: RequestDetails,
    title: 'requests',
    dynamicLocation: 'request-detail',
  },
  {
    path: '/dashboard/create-new-request',
    component: CreateNewRequest,
    title: 'requests',
    location: 'new_request',
  },
  //──── Specialists ───────────────────────────────────────────────────────────────────────
  {
    path: '/dashboard/specialists',
    component: Specialists,
    title: 'specialists',
    location: 'specialists',
  },
  {
    path: '/dashboard/candidates',
    component: Candidates,
    title: 'specialists',
    location: 'candidates',
  },
  {
    path: '/dashboard/new-candidate',
    component: NewCandidate,
    title: 'specialists',
    location: 'new_candidate',
  },
  {
    path: '/dashboard/specialist-details',
    component: SpecialistDetails,
    title: 'specialists',
    dynamicLocation: 'specialist',
  },
  //──── Clients ───────────────────────────────────────────────────────────────────────────
  {
    path: '/dashboard/clients',
    // component: ClientList,
    component: Clients,
    title: 'clients',
    location: 'clients',
  },
  {
    path: '/dashboard/client-detail',
    component: ClientDetail,
    title: 'clients',
    dynamicLocation: 'client',
  },
  {
    path: '/dashboard/cities',
    // component: ClientList,
    component: CityList,
    title: 'cities',
    location: 'cities',
  },
  {
    path: '/dashboard/city-detail/:id',
    component: CityDetail,
    title: 'cities',
    location: 'cities',
  },
  {
    path: '/dashboard/city-edit/:id',
    component: CityEdit,
    title: 'cities',
    dynamicLocation: 'city',
  },
  //──── Settings ──────────────────────────────────────────────────────────────────────────
  {
    path: '/dashboard/settings',
    component: Settings,
    location: 'settings',
    noBack: true,
  },
  //──── Team ──────────────────────────────────────────────────────────────────────────────
  {
    path: '/dashboard/members',
    component: Team,
    title: 'team',
    location: 'members',
  },
  {
    path: '/dashboard/member-detail',
    component: MemberDetail,
    title: 'members',
    dynamicLocation: 'member',
  },
  //──── CMS ───────────────────────────────────────────────────────────────────────────────
  {
    path: '/dashboard/cms-pages',
    component: ContentList,
    title: 'cms',
    location: 'pages',
  },
  {
    path: '/dashboard/page-editor',
    component: ContentDetail,
    title: 'cms',
    dynamicLocation: 'contentName',
  },
];

const Dashboard = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const params = queryString.parse(history?.location?.search);

  const { user, gotoSignInPage } = useAuth({ token: params['token'] });

  useEffect(() => {
    if (!user) {
      gotoSignInPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gotoSignInPage, user, params]);

  return (
    <Page>
      <BrowserRouter>
        <Sidebar isOpen={isOpen} />
        <BackofficePage bg='white' isOpen={isOpen}>
          <ToggleSidebarBtnContainer>
            <Button
              action={() => setIsOpen(!isOpen)}
              btnType='transparent'
              icon='Menu'
            />
          </ToggleSidebarBtnContainer>
          <NavigationBar
            user={user}
            location={history.location}
            routes={routes}
          />
          <Switch>
            <Route path={'/dashboard'} exact component={InfoGraphs} />
            {routes.map((route, index) => {
              return (
                <Route
                  key={'boRoute' + index}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </BackofficePage>
      </BrowserRouter>
    </Page>
  );
};

export default Dashboard;
