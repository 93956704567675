import React, { useState } from "react";
import { Box, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useGetCitiesQuery, useEnableAllCitiesMutation } from "./citySlice";
import { GridFilterModel } from "@mui/x-data-grid";
import { CityTable } from "./components/CityTable";
import { CityFilterComponent } from "./components/CityFilterComponent";
import { useHistory } from "react-router-dom";

export const CityList = () => {
  const [options, setOptions] = useState({
    page: 1,
    search: "",
    perPage: 25,
    price_category: "",
    rowsPerPage: [25, 50, 100],
  });

  const { data, isFetching, error } = useGetCitiesQuery(options);
  const history = useHistory();
  const [enableAllCities] = useEnableAllCitiesMutation();
  const [openEnableModal, setOpenEnableModal] = useState(false);

  function setPaginationModel(paginateModel: { page: number; pageSize: number }) {
    setOptions({ ...options, page: paginateModel.page + 1, perPage: paginateModel.pageSize });
  }

  function handleFilterChange(filterModel: GridFilterModel) {
    if (!filterModel.quickFilterValues?.length) {
      return setOptions({ ...options, search: "" });
    }
    const search = filterModel.quickFilterValues.join(" ");
    setOptions({ ...options, search });
  }

  function handleCityFilterChange(filters: Record<string, string>) {
    setOptions((prev) => ({ ...prev, ...filters, page: 1 }));
  }

  const handleEnableCities = async () => {
    try {
      await enableAllCities().unwrap();
      alert("Todos os municípios foram habilitados com sucesso!");
      setOpenEnableModal(false);
    } catch (error) {
      alert("Erro ao habilitar municípios.");
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CityFilterComponent onFilterChange={handleCityFilterChange} />
        <Button variant="contained" color="primary" onClick={() => setOpenEnableModal(true)}>
          Habilitar Municípios
        </Button>
      </Paper>

      <Dialog open={openEnableModal} onClose={() => setOpenEnableModal(false)}>
        <DialogTitle>Habilitar Municípios</DialogTitle>
        <DialogContent>
          Tem certeza que deseja habilitar todos os municípios?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEnableModal(false)}>Cancelar</Button>
          <Button onClick={handleEnableCities} color="primary">Confirmar</Button>
        </DialogActions>
      </Dialog>

      <CityTable
        cities={data}
        isFetching={isFetching}
        paginationModel={{
          pageSize: 25,
          page: 0,
        }}
        handleSetPaginationModel={setPaginationModel}
        handleFilterChange={handleFilterChange}
      />
    </Box>
  );
};