import { Result, Results, CityParams, City } from "../../types/City";
import { apiSlice } from "../api/apiSlice";
import { Price } from "../../types/City";

const endpointUrl = "/cities";

function parseQueryParams(params: CityParams) {
  const query = new URLSearchParams();

  if (params.page) {
    query.append("page", params.page.toString());
  }

  if (params.perPage) {
    query.append("per_page", params.perPage.toString());
  }

  if (params.search) {
    query.append("search", params.search);
  }

  if (params.price_category) {
    query.append("price_category", params.price_category);
  }

  return query.toString();
}

function updatePriceMutation(price: Partial<Price> & { id: number; update_all?: boolean }) {
  return { url: `/prices/${price.id}`, method: "PUT", body: price };
}

function getCities({ page = 1, perPage = 10, search = "", price_category = "" }) {
  const params = { page, perPage, search, price_category };

  return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createCityMutation(city: City) {
  return { url: endpointUrl, method: "POST", body: city };
}

function updateCityMutation(city: City) {
  return {
    url: `${endpointUrl}/${city.id}`,
    method: "PUT",
    body: city,
  };
}

function deleteCityMutation({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: "DELETE",
  };
}

function enableAllCitiesMutation() {
  return { url: `${endpointUrl}/enable_all`, method: "POST" };
}

function getCity({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

export const citiesApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCities: query<Results, CityParams>({
      query: getCities,
      providesTags: ["Cities"],
    }),
    updatePrice: mutation<Price, Partial<Price> & { id: number; update_all?: boolean }>({
      query: updatePriceMutation,
      invalidatesTags: ["Cities"],
    }),
    getCity: query<Result, { id: string }>({
      query: getCity,
      providesTags: ["Cities"],
    }),
    createCity: mutation<Result, City>({
      query: createCityMutation,
      invalidatesTags: ["Cities"],
    }),
    updateCity: mutation<Result, City>({
      query: updateCityMutation,
      invalidatesTags: ["Cities"],
    }),
    deleteCity: mutation<void, { id: string }>({
      query: deleteCityMutation,
      invalidatesTags: ["Cities"],
    }),
    enableAllCities: mutation<void, void>({
      query: enableAllCitiesMutation,
      invalidatesTags: ["Cities"],
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useUpdatePriceMutation,
  useCreateCityMutation,
  useUpdateCityMutation,
  useGetCityQuery,
  useDeleteCityMutation,
  useEnableAllCitiesMutation,
} = citiesApiSlice;
