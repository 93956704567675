import React, { useState } from "react";
import { Box, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import useTranslate from "../../polyglot/useTranslate";

interface CityFilterComponentProps {
  onFilterChange: (filters: Record<string, string>) => void;
}

export const CityFilterComponent: React.FC<CityFilterComponentProps> = ({ onFilterChange }) => {
  const translate = useTranslate("filters");
  const [priceCategory, setPriceCategory] = useState("");

  const handleFilterChange = () => {
    const filters: Record<string, string> = {};
    if (priceCategory) {
      filters["price_category"] = priceCategory;
    }
    onFilterChange(filters);
  };

  return (
    <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap" }}>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
        <InputLabel>Categoria de Preço</InputLabel>
        <Select
          value={priceCategory}
          onChange={(e) => setPriceCategory(e.target.value)}
          label={"Categoria de Preço"}
        >
          <MenuItem value="">Mostrar Tudo</MenuItem>
          <MenuItem value="P1">P1</MenuItem>
          <MenuItem value="P2">P2</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" onClick={handleFilterChange}>
        Aplicar Filtro
      </Button>
    </Box>
  );
};