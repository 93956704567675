import React from "react";
import { useParams } from "react-router-dom";
import { useGetCityQuery, useUpdatePriceMutation } from "./citySlice";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import { ShowCityDataPrices } from "./components/ShowCityDataPrices";

export const CityDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data: cityData, isFetching, error } = useGetCityQuery({ id });
  const [updatePrice] = useUpdatePriceMutation();

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !cityData) {
    return (
      <Box mt={4}>
        <Typography variant="h6" color="error">
          Erro ao carregar os detalhes da cidade.
        </Typography>
      </Box>
    );
  }

  const { name, status, price_category } = cityData.data.attributes;

  // Função para atualizar o price.
  // Agora o payload inclui o parâmetro update_all que indica se a atualização deve ser feita em todos os municípios da mesma categoria.
  const handleUpdatePrice = async (
    priceId: number,
    updatedPrice: Partial<any>,
    updateAll: boolean
  ) => {
    // Incluímos update_all no payload
    await updatePrice({ id: priceId, ...updatedPrice, update_all: updateAll }).unwrap();
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4, mb: 4, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          {name}
        </Typography>
        <Typography variant="subtitle1">Status: {status}</Typography>
        <Typography variant="subtitle1">
          Categoria de Preço: {price_category}
        </Typography>
      </Paper>
      <ShowCityDataPrices
        cityData={cityData.data}
        onUpdatePrice={handleUpdatePrice}
      />
    </Box>
  );
};
