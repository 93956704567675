import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { AppBar, Box, CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";

import { ModalProvider } from 'styled-react-modal';
import theme, { TOAST_CONTAINER_STYLE } from 'Theme';
import { Background } from 'Components/Modal/style';
import { Icon } from 'ui-55';
import { ToastContainer } from 'react-toastify';
import AppRouter from 'router';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { lightTheme } from "./config/theme";

const StyledToastContainer = styled(ToastContainer)`
${({ theme }) => TOAST_CONTAINER_STYLE(theme)}
`;
function App() {



  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={lightTheme}>
      <StyledToastContainer
        hideProgressBar={true}
        position='top-center'
        closeOnClick
        closeButton={<Icon name='Error' />}
      />
      <ModalProvider backgroundComponent={Background}>
        {(<AppRouter />) || ''}
      </ModalProvider>
    </MuiThemeProvider>
    </ThemeProvider>
  );
}

App.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'App',
};
export default App;
