import React from "react";
import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { City } from "../../../types/City";

type Props = {
  city: City;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handlePriceCategoryChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export function CityForm({
  city,
  isDisabled = false,
  isLoading = false,
  handleSubmit,
  handlePriceCategoryChange,
}: Props) {
  return (
    <Box p={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Nome (Somente exibição) */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="name"
                label="Nome da Cidade"
                value={city.attributes.name || ""}
                disabled
              />
            </FormControl>
          </Grid>

          {/* Status (Somente exibição) */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="status"
                label="Status"
                value={city.attributes.status || ""}
                disabled
              />
            </FormControl>
          </Grid>

          {/* Categoria de Preço (Editável) */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="price_category"
                label="Categoria de Preço"
                value={city.attributes.price_category || ""}
                disabled={isDisabled}
                onChange={handlePriceCategoryChange}
              />
            </FormControl>
          </Grid>

          {/* Ações */}
          <Grid item xs={12}>
            <Box display="flex" gap={2}>
              <Button variant="contained" component={Link} to="/cities">
                Voltar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isDisabled || isLoading}
              >
                {isLoading ? "Salvando..." : "Guardar"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
