// ShowCityDataPrices.tsx
import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

// Tipos conforme sua aplicação
export interface Price {
  id: number;
  service_id: number;
  client_type: "individual" | "business";
  unit: string;
  km_price: number;
  client_urgency_tax: number;
  specialist_urgency_tax: number;
  client_price_standard: number;
  client_price_premium?: number;
  specialist_price_standard: number;
  specialist_price_premium: number;
  pack_prices: any;
  active: boolean;
}

export interface Service {
  id: number;
  name: string;
  parent_category: string;
  has_km_price: boolean;
  content_key: string | null;
  slug: string;
  intro: string;
  description: string;
  cover: string;
  default_price: string | null;
  default_unit: string | null;
  active_cities: number[];
  prices: Price[];
}

export interface CityAttributes {
  name: string;
  status: string;
  price_category: string;
  active_services: Service[];
}

export interface CityData {
  id: string;
  type: string;
  attributes: CityAttributes;
}

interface ShowCityDataPricesProps {
  cityData: CityData;
  onUpdatePrice: (
    priceId: number,
    updatedPrice: Partial<Price>,
    updateAll: boolean
  ) => Promise<void>;
}

// Opções para o Autocomplete do campo "Unidade"
const unitOptions = [
  { label: "hour", value: "hour" },
  { label: "day", value: "day" },
  { label: "person", value: "person" },
];

/**
 * Formulário de edição do Price.
 * Permite alterar: unit, km_price, client_urgency_tax, specialist_urgency_tax,
 * client_price_standard, client_price_premium, specialist_price_standard, specialist_price_premium
 * e active.
 * Exibe também a categoria de preço (P1 ou P2) e um checkbox para atualizar em todos os municípios da categoria.
 */
const PriceEditForm: React.FC<{
  initialPrice: Price;
  priceCategory: string;
  onSubmit: (updatedPrice: Partial<Price>, updateAll: boolean) => void;
}> = ({ initialPrice, priceCategory, onSubmit }) => {
  const [formValues, setFormValues] = useState<Partial<Price>>({
    unit: initialPrice.unit,
    km_price: initialPrice.km_price,
    client_urgency_tax: initialPrice.client_urgency_tax,
    specialist_urgency_tax: initialPrice.specialist_urgency_tax,
    client_price_standard: initialPrice.client_price_standard,
    client_price_premium: initialPrice.client_price_premium,
    specialist_price_standard: initialPrice.specialist_price_standard,
    specialist_price_premium: initialPrice.specialist_price_premium,
    active: initialPrice.active,
  });

  const [updateAll, setUpdateAll] = useState<boolean>(false);

  // Atualiza os campos via input text
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement;
    const { name, value, type, checked } = target;
    setFormValues((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? parseFloat(value)
          : value,
    }));
  };

  // Atualiza o campo unit via Autocomplete
  const handleUnitChange = (_: any, newValue: { label: string; value: string } | null) => {
    setFormValues((prev) => ({
      ...prev,
      unit: newValue ? newValue.value : "",
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formValues, updateAll);
  };

  return (
    <Box component="form" id="price-edit-form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Editar Preço
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={unitOptions}
            value={unitOptions.find((option) => option.value === formValues.unit) || null}
            onChange={handleUnitChange}
            renderInput={(params) => (
              <TextField {...params} label="Unidade" variant="outlined" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Km Price"
            name="km_price"
            type="number"
            value={formValues.km_price !== undefined ? formValues.km_price : ""}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Client Urgency Tax"
            name="client_urgency_tax"
            type="number"
            value={
              formValues.client_urgency_tax !== undefined
                ? formValues.client_urgency_tax
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Specialist Urgency Tax"
            name="specialist_urgency_tax"
            type="number"
            value={
              formValues.specialist_urgency_tax !== undefined
                ? formValues.specialist_urgency_tax
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Client Price Standard"
            name="client_price_standard"
            type="number"
            value={
              formValues.client_price_standard !== undefined
                ? formValues.client_price_standard
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Client Price Premium"
            name="client_price_premium"
            type="number"
            value={
              formValues.client_price_premium !== undefined
                ? formValues.client_price_premium
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Specialist Price Standard"
            name="specialist_price_standard"
            type="number"
            value={
              formValues.specialist_price_standard !== undefined
                ? formValues.specialist_price_standard
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Specialist Price Premium"
            name="specialist_price_premium"
            type="number"
            value={
              formValues.specialist_price_premium !== undefined
                ? formValues.specialist_price_premium
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.active || false}
                onChange={handleChange}
                name="active"
              />
            }
            label="Ativo"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Categoria de Preço: {priceCategory}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={updateAll}
                onChange={(e) => setUpdateAll(e.target.checked)}
                name="updateAll"
              />
            }
            label="Atualizar preço em todos os municípios desta categoria"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const ShowCityDataPrices: React.FC<ShowCityDataPricesProps> = ({ cityData, onUpdatePrice }) => {
  const [selectedPrice, setSelectedPrice] = useState<(Price & { serviceName: string }) | null>(null);
  const [priceModalOpen, setPriceModalOpen] = useState(false);

  // Separa os preços de cada serviço em dois grupos
  const individualPrices = useMemo(() => {
    return cityData.attributes.active_services
      .map((service) => {
        const price = service.prices.find((p) => p.client_type === "individual");
        return price ? { serviceName: service.name, ...price } : undefined;
      })
      .filter((p): p is Price & { serviceName: string } => p !== undefined);
  }, [cityData]);

  const businessPrices = useMemo(() => {
    return cityData.attributes.active_services
      .map((service) => {
        const price = service.prices.find((p) => p.client_type === "business");
        return price ? { serviceName: service.name, ...price } : undefined;
      })
      .filter((p): p is Price & { serviceName: string } => p !== undefined);
  }, [cityData]);

  const handleRowClick = (price: Price & { serviceName: string }) => {
    setSelectedPrice(price);
    setPriceModalOpen(true);
  };

  const handleModalClose = () => {
    setPriceModalOpen(false);
    setSelectedPrice(null);
  };

  const handleFormSubmit = async (updatedFields: Partial<Price>, updateAll: boolean) => {
    if (selectedPrice) {
      try {
        await onUpdatePrice(selectedPrice.id, updatedFields, updateAll);
        handleModalClose();
      } catch (error) {
        console.error("Erro ao atualizar o preço:", error);
      }
    }
  };

  // Configuração das colunas para o DataGrid
  const columns: GridColDef[] = [
    { field: "serviceName", headerName: "Serviço", flex: 1 },
    { field: "unit", headerName: "Unidade", width: 100 },
    { field: "client_price_standard", headerName: "Preço Standard", width: 150 },
    { field: "client_price_premium", headerName: "Preço Premium", width: 150 },
  ];

  return (
    <Box>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5">
            Preços do Município: {cityData.attributes.name}
          </Typography>
        </CardContent>
      </Card>

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Preços para Individual
          </Typography>
          <Box sx={{ height: 400, width: "100%", p: 1 }}>
            <DataGrid
              rows={individualPrices}
              columns={columns}
              pagination
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              onPaginationModelChange={(model) =>
                console.log("Individual pagination:", model)
              }
              getRowId={(row) => row.id}
              onRowClick={(params) => handleRowClick(params.row)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Preços para Business
          </Typography>
          <Box sx={{ height: 400, width: "100%", p: 1 }}>
            <DataGrid
              rows={businessPrices}
              columns={columns}
              pagination
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              onPaginationModelChange={(model) =>
                console.log("Business pagination:", model)
              }
              getRowId={(row) => row.id}
              onRowClick={(params) => handleRowClick(params.row)}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog open={priceModalOpen} onClose={handleModalClose} maxWidth="sm" fullWidth>
        <DialogTitle>Editar Preço</DialogTitle>
        <DialogContent>
          {selectedPrice && (
            <PriceEditForm
              initialPrice={selectedPrice}
              priceCategory={cityData.attributes.price_category}
              onSubmit={handleFormSubmit}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", gap: 2 }}>
          <Button onClick={handleModalClose} variant="outlined">
            Cancelar
          </Button>
          {/* O botão "Guardar" dispara a submissão do formulário PriceEditForm através do id */}
          <Button variant="contained" type="submit" form="price-edit-form">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
