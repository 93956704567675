import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import useStorage from 'Hooks/useStorage';
import CONFIG from 'Config';

const baseUrl = CONFIG.apiOrigin;


const tokenFromLocalStorage = localStorage.getItem("AUTH_TOKEN");
const tokenObj = tokenFromLocalStorage ? JSON.parse(tokenFromLocalStorage) : null;

let token = null;
if (tokenObj && tokenObj.token) {
    const tokenParts = tokenObj.token.split(' ');
    if (tokenParts.length === 2) {
        token = tokenParts[1];
    }
}

export const apiSlice = createApi({
    reducerPath: "api",
    tagTypes: ["Languages", "Clients", "Settings", "Cities"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState()).authUser.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({}),
});
