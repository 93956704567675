import React, { useState } from "react";
import { Box, Button, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid, GridColDef, GridFilterModel, GridToolbar, ptPT } from "@mui/x-data-grid";
import { Results } from "../../../types/City";
import { useDeleteCityMutation } from "../citySlice";
import useTranslate from "../../polyglot/useTranslate";
import { useHistory } from "react-router-dom";

type Props = {
  cities: Results | undefined;
  paginationModel: object;
  isFetching: boolean;
  handleSetPaginationModel: (paginateModel: { page: number; pageSize: number }) => void;
  handleFilterChange: (filterModel: GridFilterModel) => void;
};

export function CityTable({
  cities,
  paginationModel,
  isFetching,
  handleSetPaginationModel,
  handleFilterChange,
}: Props) {
  const translate = useTranslate("cities");
  const [deleteCity, { isLoading }] = useDeleteCityMutation();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">("success");
  const [alertMessage, setAlertMessage] = useState("");
  const history = useHistory();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState<string | null>(null);

  const handleAlertClose = () => setAlertOpen(false);

  const handleOpenConfirm = (id: string) => {
    setSelectedCityId(id);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setSelectedCityId(null);
  };

  const handleRowClick = (params: any) => {
    history.push(`/dashboard/city-detail/${params.id}`);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", type: "string", width: 100, hideable: false },
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "price_category", headerName: "Categoria de Preço", flex: 1 },
  ];

  function mapDataToGridRows(data: Results) {
    return data.data.map((city) => ({
      id: city.id,
      name: city.attributes.name,
      status: city.attributes.status,
      price_category: city.attributes.price_category,
    }));
  }

  const rows = cities ? mapDataToGridRows(cities) : [];
  const rowCount = Number(cities?.meta["Total-Count"]) || 0;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 600, width: "100%", boxShadow: 3, borderRadius: 2, bgcolor: "background.paper", overflow: "hidden" }}>
      <DataGrid

        columns={columns}
        rows={rows}
        filterMode="server"
        rowCount={rowCount}
        loading={isFetching}
        paginationMode="server"
        checkboxSelection={false}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 }, csvOptions: { disableToolbarButton: true }, printOptions: { disableToolbarButton: true } } }}
        onPaginationModelChange={handleSetPaginationModel}
        onFilterModelChange={handleFilterChange}
        onRowClick={handleRowClick}
        localeText={ptPT.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          border: "none",
          "& .MuiDataGrid-columnHeaders": {
            bgcolor: "primary.main",
            color: "#FFFFFF",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer", // Alteração: Adiciona cursor de ponteiro
            "&:hover": {
              bgcolor: "grey.100",
            },
          },
          "& .MuiDataGrid-cell": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      />
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
