import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useTranslate } from '../../features/polyglot';

import { Logo } from 'ui-55';

import StyledSidebar, {
  NavSection,
  NavLink,
  NavHeader,
  LogoContainer,
  LogoLink,
} from './style';

const Sidebar = ({ isOpen }) => {
  const translate = useTranslate('routes');

  // eslint-disable-next-line react/prop-types
  const SidebarLink = ({ routePath, to, text, children, isLogo }) => {
    let match = useRouteMatch(routePath);

    return isLogo ? (
      <LogoLink to={to} active={match ? 'SIM' : undefined}>
        {text ? <p>{text}</p> : children}
      </LogoLink>
    ) : (
      <NavLink to={to} active={match ? 'SIM' : undefined}>
        {text ? <p>{text}</p> : children}
      </NavLink>
    );
  };

  return (
    <StyledSidebar isOpen={isOpen}>
      <LogoContainer>
        <SidebarLink
          routePath={{ path: '/dashboard', exact: true }}
          to='/dashboard'
          isLogo={true}
        >
          <Logo color={'white'} />
        </SidebarLink>
      </LogoContainer>

      <NavSection>
        <NavHeader>{translate('services')}</NavHeader>
        <SidebarLink
          routePath='/dashboard/requests?kanban=true'
          to='/dashboard/requests?kanban=true'
          text={translate('requests')}
        />
        <SidebarLink
          routePath='/dashboard/archive?archived=true&page=1'
          to='/dashboard/archive?archived=true&page=1'
          text={translate('archive')}
        />
      </NavSection>
      <NavSection>
        <NavHeader>{translate('specialists')}</NavHeader>
        <SidebarLink
          routePath='/dashboard/candidates'
          to='/dashboard/candidates'
          text={translate('candidates')}
        />

        <SidebarLink
          routePath='/dashboard/specialists?page=1'
          to='/dashboard/specialists?page=1'
          text={translate('specialists')}
        />
      </NavSection>

      <NavSection>
        <NavHeader>{translate('clients')}</NavHeader>
        <SidebarLink
          routePath='/dashboard/clients?page=1'
          to='/dashboard/clients?page=1'
          text={translate('clients')}
        />
        <SidebarLink
          routePath='/dashboard/cities?page=1'
          to='/dashboard/cities?page=1'
          text={"Preços"}
        />
      </NavSection>


      <NavSection>
        <NavHeader>{translate('team')}</NavHeader>
        <SidebarLink
          routePath='/dashboard/members'
          to='/dashboard/members'
          text={translate('members')}
        />
      </NavSection>
      <NavSection>

        <NavHeader>{translate('cms')}</NavHeader>
        <SidebarLink
          routePath='/dashboard/cms-pages'
          to='/dashboard/cms-pages'
          text={translate('pages')}
        />
      </NavSection>
      <NavSection>
        <NavHeader>{translate('tags')}</NavHeader>
        <SidebarLink
          routePath='/dashboard/tags'
          to='/dashboard/tags'
          text={translate('tag')}
        />
      </NavSection>
    </StyledSidebar>
  );
};

export default Sidebar;
