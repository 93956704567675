import { createTheme } from "@mui/material";
import { ptPT } from "@mui/material/locale";

export const lightTheme  = createTheme(
  {
    palette: {
      mode: "light",
      background: {
        default: "#F8F9FA",
        paper: "#FFFFFF",
      },
      primary: {
        main: "rgb(240, 81, 58)", // Laranja forte para primária
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "rgb(254, 195, 90)", // Amarelo para botões, conforme solicitado
        contrastText: "#333333",
      },
      error: {
        main: "#D32F2F",
      },
      text: {
        primary: "#333333",
        secondary: "#666666",
        disabled: "#9E9E9E",
      },
      divider: "#E0E0E0",
    },
    typography: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      h1: { fontWeight: 700, fontSize: "2.5rem" },
      h2: { fontWeight: 600, fontSize: "2rem" },
      body1: { fontWeight: 400, fontSize: "1rem" },
      body2: { fontWeight: 300, fontSize: "0.875rem" },
      button: { fontWeight: 500, textTransform: "none" },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            backgroundColor: "rgb(254, 195, 90)",
            color: "#333333",
            "&:hover": {
              backgroundColor: "rgb(244, 185, 80)",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            padding: "16px",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: "1px solid #171F46", // darkBlue
            backgroundColor: "#F8F7F7",   // lightestBeige
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #D2CCC6",
            backgroundColor: "#F3E9E1", // lightBeige
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#171F46", // darkBlue
            color: "#FFFFFF",
            borderBottom: "1px solid #D2CCC6", // mediumBeige
          },
        },
      },
    },
  },
  ptPT
);