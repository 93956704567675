import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useGetCityQuery, useUpdateCityMutation } from "./citySlice";
import { City } from "../../types/City";
import { CityForm } from "./components/CityForm";

export const CityEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: cityData, isFetching } = useGetCityQuery({ id });
  const [updateCity, status] = useUpdateCityMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [cityState, setCityState] = useState<City>({} as City);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (cityData) {
      setCityState({
        id: cityData.data.id || "",
        type: cityData.data.type || "",
        attributes: cityData.data.attributes,
      });
    }
  }, [cityData]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const response = await updateCity({
        id: cityState.id,
        attributes: {
          price_category: cityState.attributes.price_category,
          name: "",
          status: "",
          active_services: []
        },
        type: ""
      }).unwrap();
      enqueueSnackbar("Categoria de preço atualizada com sucesso", { variant: "success" });
    } catch (error: any) {
      const errorMessage = error?.data?.message || "Erro ao atualizar categoria de preço";
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }

  const handlePriceCategoryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCityState({
      ...cityState,
      attributes: {
        ...cityState.attributes,
        price_category: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar("Categoria de preço atualizada com sucesso", { variant: "success" });
      setIsDisabled(false);
    }
    if (status.isError) {
      const errorMessage = (status.error as any)?.data?.message || "Erro ao atualizar categoria de preço";
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }, [enqueueSnackbar, status.isSuccess, status.isError, status.error]);

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Paper>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h4">Editar Categoria de Preço</Typography>
          </Box>
        </Box>
        <CityForm
          isLoading={status.isLoading}
          city={cityState}
          isDisabled={isFetching || isDisabled}
          handleSubmit={handleSubmit}
          handlePriceCategoryChange={handlePriceCategoryChange}
        />
      </Paper>
    </Box>
  );
};
